.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

#hit-counter {
    display: none;
    /* font-family: serif; */
    font-size: 9px;
    background-color: rgba(256, 256, 256, 0.418);
    color: #000;
    padding: 4px 8px;
    position: fixed;
    right: 0px;
    bottom: 0px;
    border-radius: 18px 0 0 18px;
    /* margin: 24px; */
    z-index: 9999999;
}

#hit-counter:hover {
    font-size: 14px;
    /* border-radius: 18px; */
    transition: font-size 0.4s linear;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}