.homepage_section {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}

.bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #ffffff88, #ffffff00);
    transition: 0.4s ease-in;
}

.bg2 {
    position: absolute;
    opacity: 0.09;
    /* background: url(/assets/img/pattern.png) no-repeat; */
    width: 100%;
    height: 100%;
    background-size: cover;
    /* filter: drop-shadow(1px 1px 14px white) */
}

.bg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
}

.content {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content .textBox {
    position: relative;
    min-width: 600px;
    width: 600px;
    text-align: left;
}

.content .textBox h1 {
    color: #fff;
    font-size: 3em;
    line-height: 1em;
    font-weight: 700;
    letter-spacing: 0px;
}


/* .content .textBox h1::after{
  content: '';
  letter-spacing: 2px;
  transition: letter-spacing 0.4s linear;
} */

.content .textBox p {
    color: #fff;
    margin-top: 20px;
    max-width: 520px;
    line-height: 1.5em;
    min-height: 2em;
}

.content .textBox a {
    display: inline-block;
    margin-top: 20px;
    padding: 8px 20px;
    background: #fff;
    color: #333;
    border-radius: 40px;
    font-weight: 500;
    letter-spacing: 1;
    text-decoration: none;
}

.content .textBox a:hover {
    font-weight: 600;
    /* background: #333;
  color: #fff; */
    letter-spacing: 1.05px;
    transition: letter-spacing 200ms linear;
    box-shadow: #fff 1px 1px 10px;
    /* text-decoration: underline; */
}

.content .imgBox {
    position: relative;
    z-index: 1;
    width: 600px;
    display: flex;
    justify-content: flex-end;
}

.content .imgBox img {
    max-width: 370px;
    max-height: 410px;
    /* visibility: hidden; */
    /* transform: rotate(9deg); */
    /* transition: 0.4s ease-in; */
    filter: drop-shadow(1px 1px 48px white)
}

.candyImg {
    transition: 0.4s ease-in;
}

.image-overlay {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
}

.content .imgBox img:hover {
    /* max-width: 400px; */
    /* transition: max-width 0.3s ease; */
    /* max-height: 420px; */
    /* visibility: hidden; */
    /* transform: rotate(9deg); */
}

.thumb {
    position: absolute;
    left: 50%;
    bottom: 15px;
    transform: translate(-50%);
    display: flex;
    max-width: 100%;
    align-items: center;
    /* justify-content: center; */
}

.thumb li {
    list-style: none;
    display: flex;
    margin: 12px;
    cursor: pointer;
    /* transition: 0.5s; */
    height: 48px;
    width: 48px;
    background: rgba(255, 255, 255, 0.1);
    padding: 5px;
    /* border: 2px solid rgba(255, 255, 255, 0); */
    border-radius: 8px;
    text-align: center;
    align-items: center;
    min-height: 48px;
    min-width: 48px;
    justify-content: center;
    border: none;
    content: '';
}

.thumb li.active {
    border: 1px solid rgb(255, 255, 255, 1);
    /* min-height: 60px;
    min-width: 60px; */
    /* box-shadow: 1px 1px 30px rgba(255, 255, 255, 0.7); */
    filter: drop-shadow(0px 0px 12px white)
}

.thumb li.active::before {
    /* counter-increment: 1; */
    position: absolute;
    /* top: -200px;
	left: -100px; */
    top: -120px;
    left: -100px;
    /* content: attr(data-text); */
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.1);
    font-size: 5em;
    font-weight: 700;
    pointer-events: none;
    opacity: 1;
}


/* .thumb li.active::before {
    opacity: 1;
    transition: opacity 0.5s ease;
} */

.thumb li img {
    max-width: 42px;
    max-height: 42px;
    height: 42px;
}

.social {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.social a {
    display: inline-block;
    margin: 5px 0;
    transform: scale(0.8);
    /* filter: invert(1); */
}

.social a img {
    max-width: 30px;
}

.social a:hover {
    transform: scale(1);
    transition: transform 0.2s linear;
    filter: drop-shadow(1px 1px 6px white)
}


/* Responsiveness */

@media (max-width: 981px) {
    .bg::before {
        display: none;
    }
    .homepage_section {
        padding: 20px;
        padding-bottom: 120px;
    }
    .content {
        margin-top: 70px;
        flex-direction: column;
        height: 70vh;
        justify-content: space-evenly;
    }
    .content .textBox {
        max-width: 100%;
        min-width: 100%;
    }
    .content .textBox h1 {
        font-size: 1.5em;
        margin-bottom: 8px;
    }
    .content .textBox p {
        margin-top: 0;
        font-size: 12px;
    }
    .content .imgBox {
        justify-content: center;
    }
    .content .imgBox img {
        max-width: 250px;
        max-height: 260px;
    }
    .content .textBox a {
        padding: 8px 12px;
        background: #fff;
        color: #333;
        border-radius: 40px;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 1;
        text-decoration: none;
    }
    .thumb {
        overflow: scroll;
        padding-bottom: 8px;
        overflow-y: hidden;
        /* max-width: 95%; */
    }
    .thumb li {
        margin: 0 3px;
    }
    .thumb li img {
        max-width: 34px;
        max-height: 34px;
    }
    .thumb li::before {
        position: absolute;
        top: -70px;
        right: 10px;
        font-size: 2em;
        z-index: 2;
        display: none;
    }
    .social {
        background: rgba(0, 0, 0, 0.3);
        right: 0;
        width: 50px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        z-index: 100;
        position: fixed;
    }
    /* .toggle {
      position: relative;
      width: 32px;
      height: 32px;
      cursor: pointer;
      background: url("./../../assets/candy-placeholder.svg") no-repeat center;
      background-size: 32px;
      z-index: 10000;
    } */
    /* .toggle.active {
    position: fixed;
    right: 20px;
    opacity: 0.5;
  } */
}