header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 20px 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    transition: all 0.6s ease;
}

.header-on-scrolled {
    /* background-color: rgba(27, 27, 27, 0.9); */
    background: rgb(0, 0, 0, 0.2);
    /* background: linear-gradient(
		180deg,
		rgb(51, 51, 51) 0%,
		rgba(255, 255, 255, 0) 95%
	); */
    padding: 18px 100px;
    transition: all 0.4s ease;
}

header .logo {
    position: relative;
    font-weight: 600;
    color: #fff;
    text-decoration: none;
    font-size: 1.5em;
}

header .logo .logo-img {
    width: 100px;
    margin-right: 12px;
    filter: drop-shadow(1px 1px 4px red)
}

header .logo:hover {
    filter: drop-shadow(0px 0px 8px red)
}

header .navigation {
    display: flex;
    position: relative;
}

header .navigation li {
    list-style: none;
}

header .navigation li a {
    display: inline-block;
    color: #fff;
    font-weight: 600;
    margin-left: 10px;
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 40px;
    font-size: 13px;
}

header .navigation li a.active,
header .navigation li a:hover {
    background: #fff;
    color: #333;
    box-shadow: 1px 1px 10px rgb(255, 255, 255);
}

.close-menu-btn {
    display: none;
}


/* Responsiveness */

@media (max-width: 981px) {
    header {
        padding: 20px;
    }
    header .logo {
        font-size: 1.2em;
    }
    header .logo .logo-img {
        width: 70px;
        margin-right: 8px;
    }
    .header-on-scrolled {
        padding: 12px 20px;
        transition: all 0.3s;
    }
    header .navigation {
        display: none;
        width: 0;
    }
    /* header .navigation.active {
    position: fixed;
    top: 64px;
    right: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #00000090;
    z-index: 10;
  } */
    header .navigation.active {
        position: fixed;
        top: 22px;
        right: 0px;
        width: 40%;
        padding: 24px 0px;
        height: auto;
        /* padding: 20px 0; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #000000d9;
        box-shadow: white 1px 1px 28px;
        border-radius: 18px 0px 0 18px;
        z-index: 100;
        transition: width 4000ms linear;
        /* box-shadow: 1px 1px 40px white; */
        /* border-radius: 20px; */
    }
    header .navigation li a {
        font-size: 0.8em;
        margin: 6px 0px;
    }
    header .navigation li a {
        /* margin-left: 10px;
      padding: 8px 16px;
      border-radius: 40px; */
    }
    header .navigation li a.active,
    header .navigation li a:hover {
        background: #fff;
        color: #333;
        box-shadow: 1px 1px 10px rgb(255, 255, 255);
        padding: 6px 8px;
    }
    .toggle {
        position: relative;
        width: 48px;
        height: 48px;
        cursor: pointer;
        background: url("./../../assets/menu.svg") no-repeat center;
        background-size: 48px;
        z-index: 10000;
        opacity: 1;
        /* fill: #fff; */
        /* position: fixed; */
    }
    .toggle.active {
        position: fixed;
        right: 20px;
        display: none;
        /* width: 48px;
    height: 48px;
    background-size: 48px;
    background: url("./../../assets/candy-placeholder.svg") no-repeat center;
    opacity: 0.9;
    transition: 00.3s ease-in; */
    }
    .close-menu-btn {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        padding: 8px;
        color: white;
        width: 34px;
    }
}