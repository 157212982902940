@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,600,700,800,900&display=swap");

:root {
	--primaryColor: #e02f6b;
	--secondaryColor:  #ffbcd9 ;
	--blue: #0000ff;
	--blueDark: #18293c;
	--orange: #ffa500;
	--greenYellow: #cddc39;
	--pinkLight: #efa2b4;
	--cyanLight: #aef1ee;
	--white: #ffffff;
	--whiteAphla40: rgba(255, 255, 255, 0.4);
	--whiteAphla25: rgba(255, 255, 255, 0.25);
	--backdropFilterBlur: blur(5px);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.glass-page {
	background-color: var(--whiteAphla25);
	border: 1px solid var(--whiteAphla40);
	min-height: calc(100vh - 70px);
	border-radius: 30px;
	backdrop-filter: var(--backdropFilterBlur);
}

/* Custom Scrollbar */
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

::-webkit-scrollbar-track {
	background-color: var(--whiteAphla40);
}

::-webkit-scrollbar-thumb {
	background-color: var(--whiteAphla40);
}

.overlay {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: red;
	z-index: 200;
	opacity: 0.5;
  }
