@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,600,700,800,900&display=swap);
:root {
	--primaryColor: #e02f6b;
	--secondaryColor:  #ffbcd9 ;
	--blue: #0000ff;
	--blueDark: #18293c;
	--orange: #ffa500;
	--greenYellow: #cddc39;
	--pinkLight: #efa2b4;
	--cyanLight: #aef1ee;
	--white: #ffffff;
	--whiteAphla40: rgba(255, 255, 255, 0.4);
	--whiteAphla25: rgba(255, 255, 255, 0.25);
	--backdropFilterBlur: blur(5px);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.glass-page {
	background-color: rgba(255, 255, 255, 0.25);
	background-color: var(--whiteAphla25);
	border: 1px solid rgba(255, 255, 255, 0.4);
	border: 1px solid var(--whiteAphla40);
	min-height: calc(100vh - 70px);
	border-radius: 30px;
	-webkit-backdrop-filter: blur(5px);
	        backdrop-filter: blur(5px);
	-webkit-backdrop-filter: var(--backdropFilterBlur);
	        backdrop-filter: var(--backdropFilterBlur);
}

/* Custom Scrollbar */
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

::-webkit-scrollbar-track {
	background-color: rgba(255, 255, 255, 0.4);
	background-color: var(--whiteAphla40);
}

::-webkit-scrollbar-thumb {
	background-color: rgba(255, 255, 255, 0.4);
	background-color: var(--whiteAphla40);
}

.overlay {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: red;
	z-index: 200;
	opacity: 0.5;
  }

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

#hit-counter {
    display: none;
    /* font-family: serif; */
    font-size: 9px;
    background-color: rgba(256, 256, 256, 0.418);
    color: #000;
    padding: 4px 8px;
    position: fixed;
    right: 0px;
    bottom: 0px;
    border-radius: 18px 0 0 18px;
    /* margin: 24px; */
    z-index: 9999999;
}

#hit-counter:hover {
    font-size: 14px;
    /* border-radius: 18px; */
    transition: font-size 0.4s linear;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.homepage_section {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 90px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    overflow-x: hidden;
}

.bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #ffffff88, #ffffff00);
    transition: 0.4s ease-in;
}

.bg2 {
    position: absolute;
    opacity: 0.09;
    /* background: url(/assets/img/pattern.png) no-repeat; */
    width: 100%;
    height: 100%;
    background-size: cover;
    /* filter: drop-shadow(1px 1px 14px white) */
}

.bg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
}

.content {
    position: relative;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.content .textBox {
    position: relative;
    min-width: 600px;
    width: 600px;
    text-align: left;
}

.content .textBox h1 {
    color: #fff;
    font-size: 3em;
    line-height: 1em;
    font-weight: 700;
    letter-spacing: 0px;
}


/* .content .textBox h1::after{
  content: '';
  letter-spacing: 2px;
  transition: letter-spacing 0.4s linear;
} */

.content .textBox p {
    color: #fff;
    margin-top: 20px;
    max-width: 520px;
    line-height: 1.5em;
    min-height: 2em;
}

.content .textBox a {
    display: inline-block;
    margin-top: 20px;
    padding: 8px 20px;
    background: #fff;
    color: #333;
    border-radius: 40px;
    font-weight: 500;
    letter-spacing: 1;
    text-decoration: none;
}

.content .textBox a:hover {
    font-weight: 600;
    /* background: #333;
  color: #fff; */
    letter-spacing: 1.05px;
    transition: letter-spacing 200ms linear;
    box-shadow: #fff 1px 1px 10px;
    /* text-decoration: underline; */
}

.content .imgBox {
    position: relative;
    z-index: 1;
    width: 600px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.content .imgBox img {
    max-width: 370px;
    max-height: 410px;
    /* visibility: hidden; */
    /* transform: rotate(9deg); */
    /* transition: 0.4s ease-in; */
    -webkit-filter: drop-shadow(1px 1px 48px white);
            filter: drop-shadow(1px 1px 48px white)
}

.candyImg {
    transition: 0.4s ease-in;
}

.image-overlay {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
}

.content .imgBox img:hover {
    /* max-width: 400px; */
    /* transition: max-width 0.3s ease; */
    /* max-height: 420px; */
    /* visibility: hidden; */
    /* transform: rotate(9deg); */
}

.thumb {
    position: absolute;
    left: 50%;
    bottom: 15px;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    display: -webkit-flex;
    display: flex;
    max-width: 100%;
    -webkit-align-items: center;
            align-items: center;
    /* justify-content: center; */
}

.thumb li {
    list-style: none;
    display: -webkit-flex;
    display: flex;
    margin: 12px;
    cursor: pointer;
    /* transition: 0.5s; */
    height: 48px;
    width: 48px;
    background: rgba(255, 255, 255, 0.1);
    padding: 5px;
    /* border: 2px solid rgba(255, 255, 255, 0); */
    border-radius: 8px;
    text-align: center;
    -webkit-align-items: center;
            align-items: center;
    min-height: 48px;
    min-width: 48px;
    -webkit-justify-content: center;
            justify-content: center;
    border: none;
    content: '';
}

.thumb li.active {
    border: 1px solid rgb(255, 255, 255, 1);
    /* min-height: 60px;
    min-width: 60px; */
    /* box-shadow: 1px 1px 30px rgba(255, 255, 255, 0.7); */
    -webkit-filter: drop-shadow(0px 0px 12px white);
            filter: drop-shadow(0px 0px 12px white)
}

.thumb li.active::before {
    /* counter-increment: 1; */
    position: absolute;
    /* top: -200px;
	left: -100px; */
    top: -120px;
    left: -100px;
    /* content: attr(data-text); */
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.1);
    font-size: 5em;
    font-weight: 700;
    pointer-events: none;
    opacity: 1;
}


/* .thumb li.active::before {
    opacity: 1;
    transition: opacity 0.5s ease;
} */

.thumb li img {
    max-width: 42px;
    max-height: 42px;
    height: 42px;
}

.social {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.social a {
    display: inline-block;
    margin: 5px 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    /* filter: invert(1); */
}

.social a img {
    max-width: 30px;
}

.social a:hover {
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: -webkit-transform 0.2s linear;
    transition: transform 0.2s linear;
    transition: transform 0.2s linear, -webkit-transform 0.2s linear;
    -webkit-filter: drop-shadow(1px 1px 6px white);
            filter: drop-shadow(1px 1px 6px white)
}


/* Responsiveness */

@media (max-width: 981px) {
    .bg::before {
        display: none;
    }
    .homepage_section {
        padding: 20px;
        padding-bottom: 120px;
    }
    .content {
        margin-top: 70px;
        -webkit-flex-direction: column;
                flex-direction: column;
        height: 70vh;
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly;
    }
    .content .textBox {
        max-width: 100%;
        min-width: 100%;
    }
    .content .textBox h1 {
        font-size: 1.5em;
        margin-bottom: 8px;
    }
    .content .textBox p {
        margin-top: 0;
        font-size: 12px;
    }
    .content .imgBox {
        -webkit-justify-content: center;
                justify-content: center;
    }
    .content .imgBox img {
        max-width: 250px;
        max-height: 260px;
    }
    .content .textBox a {
        padding: 8px 12px;
        background: #fff;
        color: #333;
        border-radius: 40px;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 1;
        text-decoration: none;
    }
    .thumb {
        overflow: scroll;
        padding-bottom: 8px;
        overflow-y: hidden;
        /* max-width: 95%; */
    }
    .thumb li {
        margin: 0 3px;
    }
    .thumb li img {
        max-width: 34px;
        max-height: 34px;
    }
    .thumb li::before {
        position: absolute;
        top: -70px;
        right: 10px;
        font-size: 2em;
        z-index: 2;
        display: none;
    }
    .social {
        background: rgba(0, 0, 0, 0.3);
        right: 0;
        width: 50px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        z-index: 100;
        position: fixed;
    }
    /* .toggle {
      position: relative;
      width: 32px;
      height: 32px;
      cursor: pointer;
      background: url("./../../assets/candy-placeholder.svg") no-repeat center;
      background-size: 32px;
      z-index: 10000;
    } */
    /* .toggle.active {
    position: fixed;
    right: 20px;
    opacity: 0.5;
  } */
}
header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 20px 140px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    z-index: 1000;
    transition: all 0.6s ease;
}

.header-on-scrolled {
    /* background-color: rgba(27, 27, 27, 0.9); */
    background: rgb(0, 0, 0, 0.2);
    /* background: linear-gradient(
		180deg,
		rgb(51, 51, 51) 0%,
		rgba(255, 255, 255, 0) 95%
	); */
    padding: 18px 100px;
    transition: all 0.4s ease;
}

header .logo {
    position: relative;
    font-weight: 600;
    color: #fff;
    text-decoration: none;
    font-size: 1.5em;
}

header .logo .logo-img {
    width: 100px;
    margin-right: 12px;
    -webkit-filter: drop-shadow(1px 1px 4px red);
            filter: drop-shadow(1px 1px 4px red)
}

header .logo:hover {
    -webkit-filter: drop-shadow(0px 0px 8px red);
            filter: drop-shadow(0px 0px 8px red)
}

header .navigation {
    display: -webkit-flex;
    display: flex;
    position: relative;
}

header .navigation li {
    list-style: none;
}

header .navigation li a {
    display: inline-block;
    color: #fff;
    font-weight: 600;
    margin-left: 10px;
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 40px;
    font-size: 13px;
}

header .navigation li a.active,
header .navigation li a:hover {
    background: #fff;
    color: #333;
    box-shadow: 1px 1px 10px rgb(255, 255, 255);
}

.close-menu-btn {
    display: none;
}


/* Responsiveness */

@media (max-width: 981px) {
    header {
        padding: 20px;
    }
    header .logo {
        font-size: 1.2em;
    }
    header .logo .logo-img {
        width: 70px;
        margin-right: 8px;
    }
    .header-on-scrolled {
        padding: 12px 20px;
        transition: all 0.3s;
    }
    header .navigation {
        display: none;
        width: 0;
    }
    /* header .navigation.active {
    position: fixed;
    top: 64px;
    right: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #00000090;
    z-index: 10;
  } */
    header .navigation.active {
        position: fixed;
        top: 22px;
        right: 0px;
        width: 40%;
        padding: 24px 0px;
        height: auto;
        /* padding: 20px 0; */
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background: #000000d9;
        box-shadow: white 1px 1px 28px;
        border-radius: 18px 0px 0 18px;
        z-index: 100;
        transition: width 4000ms linear;
        /* box-shadow: 1px 1px 40px white; */
        /* border-radius: 20px; */
    }
    header .navigation li a {
        font-size: 0.8em;
        margin: 6px 0px;
    }
    header .navigation li a {
        /* margin-left: 10px;
      padding: 8px 16px;
      border-radius: 40px; */
    }
    header .navigation li a.active,
    header .navigation li a:hover {
        background: #fff;
        color: #333;
        box-shadow: 1px 1px 10px rgb(255, 255, 255);
        padding: 6px 8px;
    }
    .toggle {
        position: relative;
        width: 48px;
        height: 48px;
        cursor: pointer;
        background: url(/static/media/menu.244fe193.svg) no-repeat center;
        background-size: 48px;
        z-index: 10000;
        opacity: 1;
        /* fill: #fff; */
        /* position: fixed; */
    }
    .toggle.active {
        position: fixed;
        right: 20px;
        display: none;
        /* width: 48px;
    height: 48px;
    background-size: 48px;
    background: url("./../../assets/candy-placeholder.svg") no-repeat center;
    opacity: 0.9;
    transition: 00.3s ease-in; */
    }
    .close-menu-btn {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        padding: 8px;
        color: white;
        width: 34px;
    }
}
.footer-container {
    /* position: absolute;
	top: 20;
	left: 0; */
    width: 100%;
    padding: 42px 140px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    /* justify-content: space-between;
	align-items: center; */
    z-index: 1000;
    background-color: #00003ade;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
}

.footer-container .logo {
    position: relative;
    /* font-weight: 600; */
    color: #fff;
    text-decoration: none;
    font-size: 1em;
}

.footer-container .navigation {
    /* display: flex; */
    position: relative;
}

.footer-container .navigation li {
    list-style: none;
}

.footer-container .navigation li a {
    display: inline-block;
    color: #fff;
    font-weight: 600;
    margin-left: 10px;
    padding: 8px 16px;
    text-decoration: none;
    font-size: 13px;
}

.footer-container .navigation li a.active,
.footer-container .navigation li a:hover {
    font-size: 14px;
    letter-spacing: 1px;
    transition: letter-spacing 1000ms linear;
    /* text-decoration: underline; */
}

.footer-top--container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.getintouch-container {
    text-align: left;
    color: #fff;
}

.footer-address-div {
    margin: 24px 0 48px 0;
    display: grid;
    grid-row-gap: 12px;
    row-gap: 12px;
    font-size: 13px;
    letter-spacing: 0.4px;
}

.footer-address-div p a {
    color: #fff;
    text-decoration: none;
}

.footer-address-div p a:hover {
    color: #fff;
    /* font-size: 12px; */
    font-weight: 800;
    letter-spacing: 1px;
    transition: letter-spacing 1000ms linear;
}

.social-footer {
    display: -webkit-flex;
    display: flex;
    /* justify-content: center; */
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-left: -25px;
}

.social-footer a {
    display: inline-block;
    margin: 5px 5px 5px 0;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    /* filter: invert(1); */
    /* padding: 6px; */
    width: 68px;
    height: 68px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    /* box-shadow: #fff 0px 0px 8px; */
    background: none;
}

.social-footer a:hover {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 42px;
}

.social-footer a img {
    max-width: 48px;
    padding: 6px;
}


/* Responsiveness */

@media (max-width: 981px) {
    .footer-container {
        padding: 20px;
    }
    .footer-container .navigation {
        display: none;
    }
    .footer-container .navigation.active {
        position: fixed;
        top: 64px;
        right: 0px;
        width: 100%;
        /* padding: 24px 0px; */
        height: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background: #ffb3e6e8;
        z-index: 10;
        /* box-shadow: 1px 1px 40px white; */
        /* border-radius: 20px; */
    }
    .footer-container .navigation li a {
        font-size: 1.2em;
        margin: 6px 0px;
    }
    .toggle {
        position: relative;
        width: 32px;
        height: 32px;
        cursor: pointer;
        /* background: url("//assets/candy-placeholder.svg") no-repeat center; */
        background-size: 32px;
        z-index: 10000;
        /* position: fixed; */
    }
    .toggle.active {
        position: fixed;
        right: 20px;
        opacity: 0.5;
    }
    .footer-container .logo {
        font-size: 0.8em;
    }
    .footer-address-div {
        margin-top: 4px;
    }
    .social-footer {
        -webkit-justify-content: left;
                justify-content: left;
    }
    .social-footer a {
        margin: 0px -14px 0 0;
    }
}
