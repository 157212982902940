.footer-container {
    /* position: absolute;
	top: 20;
	left: 0; */
    width: 100%;
    padding: 42px 140px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between;
	align-items: center; */
    z-index: 1000;
    background-color: #00003ade;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
}

.footer-container .logo {
    position: relative;
    /* font-weight: 600; */
    color: #fff;
    text-decoration: none;
    font-size: 1em;
}

.footer-container .navigation {
    /* display: flex; */
    position: relative;
}

.footer-container .navigation li {
    list-style: none;
}

.footer-container .navigation li a {
    display: inline-block;
    color: #fff;
    font-weight: 600;
    margin-left: 10px;
    padding: 8px 16px;
    text-decoration: none;
    font-size: 13px;
}

.footer-container .navigation li a.active,
.footer-container .navigation li a:hover {
    font-size: 14px;
    letter-spacing: 1px;
    transition: letter-spacing 1000ms linear;
    /* text-decoration: underline; */
}

.footer-top--container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.getintouch-container {
    text-align: left;
    color: #fff;
}

.footer-address-div {
    margin: 24px 0 48px 0;
    display: grid;
    row-gap: 12px;
    font-size: 13px;
    letter-spacing: 0.4px;
}

.footer-address-div p a {
    color: #fff;
    text-decoration: none;
}

.footer-address-div p a:hover {
    color: #fff;
    /* font-size: 12px; */
    font-weight: 800;
    letter-spacing: 1px;
    transition: letter-spacing 1000ms linear;
}

.social-footer {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: row;
    margin-left: -25px;
}

.social-footer a {
    display: inline-block;
    margin: 5px 5px 5px 0;
    transform: scale(0.6);
    /* filter: invert(1); */
    /* padding: 6px; */
    width: 68px;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* box-shadow: #fff 0px 0px 8px; */
    background: none;
}

.social-footer a:hover {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 42px;
}

.social-footer a img {
    max-width: 48px;
    padding: 6px;
}


/* Responsiveness */

@media (max-width: 981px) {
    .footer-container {
        padding: 20px;
    }
    .footer-container .navigation {
        display: none;
    }
    .footer-container .navigation.active {
        position: fixed;
        top: 64px;
        right: 0px;
        width: 100%;
        /* padding: 24px 0px; */
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #ffb3e6e8;
        z-index: 10;
        /* box-shadow: 1px 1px 40px white; */
        /* border-radius: 20px; */
    }
    .footer-container .navigation li a {
        font-size: 1.2em;
        margin: 6px 0px;
    }
    .toggle {
        position: relative;
        width: 32px;
        height: 32px;
        cursor: pointer;
        /* background: url("//assets/candy-placeholder.svg") no-repeat center; */
        background-size: 32px;
        z-index: 10000;
        /* position: fixed; */
    }
    .toggle.active {
        position: fixed;
        right: 20px;
        opacity: 0.5;
    }
    .footer-container .logo {
        font-size: 0.8em;
    }
    .footer-address-div {
        margin-top: 4px;
    }
    .social-footer {
        justify-content: left;
    }
    .social-footer a {
        margin: 0px -14px 0 0;
    }
}